import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavigateFunction, useNavigate } from "react-router-dom";
import Button from "./Button";
import { genSearchParams } from "./SearchBox";
import { getAirportTitle, getHotelTitle, parseDate, renderPrice } from "./utils";
import humanizeDuration from "humanize-duration";

export type Deal = {
  startDate: string,
  durationDays: number,
  creationTime: number,
  price: number,
  prefix: string,
  hotel: string,
  origin: string | null,
  suffix: string,
  adults: number,
  children: number,
}

type AppProps = {
  deal: Deal;
};

function renderDate(date: Date): string {
  return date.getDate() + ' ' + date.toLocaleString('default', { month: 'short' });
}

function getViewDealUrl(props: AppProps): string {
  let query = genSearchParams({
    flights: true,
    tickets: true,
    hotels: true,
    adults: props.deal.adults,
    children: props.deal.children,
    origin: props.deal.origin ?? "AnyLondon",
    dest: "Orlando",
    start: parseDate(props.deal.startDate),
    duration: props.deal.durationDays,
    highlight: props.deal.hotel,
  }).toString();

  return "/search?" + query;
}

function FeaturedDeal(props: AppProps) {
  let navigate = useNavigate();
  let start = parseDate(props.deal.startDate);
  let end = new Date(start);
  end.setDate(end.getDate() + props.deal.durationDays);
  // console.log(new Date(props.deal.creationTime*1000));
  const duration = humanizeDuration(
    (new Date()).getTime() - props.deal.creationTime*1000,
    {
      units: ["d", "h", "m"],
      largest: 1,
      round: true
    }
  );

  const startYear = start.getFullYear() == end.getFullYear() ? "" : start.getFullYear().toString();

  return (
    <div className="mx-auto flex items-stretch flex-col">
      <img src={"images/" + props.deal.hotel + ".jpg"} className="rounded-md w-full" alt={getHotelTitle(props.deal.hotel)}/>
      <span className="absolute ml-2 mt-2 text-white bg-sky-800/30 rounded-md p-1 pl-2 pr-2 border-sky-900/70 border" title="Time since this deal was found">
        <FontAwesomeIcon icon={faClock} className="pr-2" />
        as of {duration} ago
      </span>
      <div className="px-2 -mt-4 grow flex">
        <div className="w-full shadow-lg rounded-md p-4 text-left grow flex flex-col">
          <h3 className="text-2xl leading-tight font-medium mt-4 ">
            {props.deal.prefix}
            <span className="before:block before:absolute before:-inset-1 before:top-[0.0rem] before:bg-shamrock relative inline-block before:text-md mx-3 " aria-hidden="true">
              <span className="relative text-white text-2xl whitespace-nowrap">{props.deal.durationDays}-day</span>
            </span>

            <p className="inline-block">{props.deal.suffix}.</p>
          </h3>
          <div className="text-zinc-400 my-4 grow">
            <p>{renderDate(start)} {startYear} - {renderDate(end)} {end.getFullYear()}</p>
            <p>{props.deal.adults} adults</p>
            <p>{getHotelTitle(props.deal.hotel)}</p>
            {
              props.deal.origin == null ? (
                <p>No flight</p>
              ) : (
                <p>Flying from {getAirportTitle(props.deal.origin)}</p>
              )
            }
          </div>
          <div className="flex items-center">
            <span className="text-xl font-bold text-ebony">{renderPrice(props.deal.price)}</span>
            <Button primary={false} className="ml-auto text-lg p-1" href={getViewDealUrl(props)}>View Deal</Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeaturedDeal;