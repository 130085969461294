import React, { useEffect, useState } from 'react';

import Navbar from './Navbar';
import Hero from './Hero';
import FeaturedDeal from './FeaturedDeal';
import Footer from './Footer';
import Wrapper from './Wrapper';
import SearchBox, { genSearchParams, SearchSettings } from './SearchBox';
import ResultsList, { SearchResponse } from './ResultsList';
import Spinner from './Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faCircleInfo, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import API_URI from './utils';
import {Helmet} from "react-helmet";
import Chart from "react-apexcharts";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, ComposedChart, ReferenceLine } from 'recharts';
import { useSearchParams } from 'react-router-dom';
import SearchResultItem from './SearchResultItem';
import { getHotelTitle } from './utils';
import PeopleCount from './SearchBox/PeopleCount';
import Select, { SingleValue } from 'react-select'

type TimeseriesPoint = {
  date: string;
  scrapeDate: string;
  latest: number;
  avg: number;
  min: number;
  max: number;
};

type HistoryResponse = {
  series: TimeseriesPoint[];
};

type HistorySettings = {
  hotel: string;
  adults: number;
  children: number;
  duration: number; // Number of days.
};

type Props = {
};

export function genHistoryParams(settings: HistorySettings): URLSearchParams {
  let params: any = {
    hotel: settings.hotel,
    adults: settings.adults.toString(),
    children: settings.children.toString(),
    duration: settings.duration.toString(),
  };
  return new URLSearchParams(params);
}

const moneyFormatter = (value: number | string | null): string => {
  if (value === null) {
    return "Not available";
  }
  if (typeof value === 'string') {
    return moneyFormatter(parseFloat(value));
  } else {
    const intl = new Intl.NumberFormat('en').format(Math.round(value));
    return "£" + intl;
  }
};

// @ts-ignore
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-gray-100/75 p-4 border-gray-200 border-2">
        <h3 className="text-lg font-bold">{label}</h3>
        <p><b>Current price:</b> {moneyFormatter(payload[0].payload.latest)} (since {payload[0].payload.scrapeDate})</p>
        <p><b>Average price:</b> {moneyFormatter(payload[0].payload.avg)}</p>
        <p><b>Minimum price:</b> {moneyFormatter(payload[0].payload.min)}</p>
        <p><b>Maximum price:</b> {moneyFormatter(payload[0].payload.max)}</p>
      </div>
    );
  }

  return null;
};

function HistoryPage(props: Props) {
  let [searchParams, setSearchParams] = useSearchParams();
  let settings: HistorySettings = {
    hotel: searchParams.get("hotel") ?? "PopCentury",
    adults: Number(searchParams.get("adults") ?? "2"),
    children: Number(searchParams.get("children")),
    duration: Number(searchParams.get("duration") ?? "14"),
  };
  const [settingsState, setSettingsState] = useState<HistorySettings>(settings);

  const [error, setError] = useState<any | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [response, setResponse] = useState<HistoryResponse | null>(null);

  // The following implements poor man's legend toggleability.
  //
  // See https://github.com/recharts/recharts/issues/590#issuecomment-713560639
  // for better ways.
  const [labelVisibility, setLabelVisibility] = useState(new Map<string, boolean>());
  const selectLine = (e: any) => {
    const existing = new Map<string, boolean>(labelVisibility);
    existing.set(e.dataKey, !existing.get(e.dataKey));
    setLabelVisibility(existing);
  };

  // https://stackoverflow.com/a/56247483/492186
  // Set up callback whenever the settings state changes to update URL in address bar.
  useEffect(
    () => {
      setSearchParams(genHistoryParams(settingsState));
      fetchHistoryData(settingsState);
    },
    [settingsState]
  )


  function fetchHistoryData(settings: HistorySettings) {
    setSettingsState(settings);
    // After 200ms of waiting, show spinner. Otherwise update "instaneously" for nicer UX.
    const timeoutID = setTimeout(() => setIsLoaded(false), 200);

    fetch(API_URI + "/history?" + genHistoryParams(settings).toString())
      .then(res => res.json())
      .then(
        (response) => {
          clearTimeout(timeoutID);
          setIsLoaded(true);
          setResponse(response);
        },
        (error) => {
          clearTimeout(timeoutID);
          setIsLoaded(true);
          setError(error);
        }
      )
  }

  let content = null;
  let max = 0;
  let min = Number.MAX_SAFE_INTEGER;

  if (error) {
    min = 0;
    max = 0;
    content = (
      <>
        <div className="flex justify-center m-8">
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-9xl text-violet" />
        </div>
        <h1 className="text-xl text-center">Our mice had some trouble</h1>
        <p className="text-sm text-center">The error we experienced is '{error.message}'. This may be a temporary problem, retry later or change your search settings.</p>
      </>
    );
  } else if (!isLoaded) {
    content = (<Spinner />);
    min = 0;
    max = 0;
  } else if (response == null || response.series.length == 0) {
    min = 0;
    max = 0;
    content = (
      <>
        <div className="flex justify-center m-8">
          <FontAwesomeIcon icon={faCircleExclamation} className="text-9xl text-shamrock" />
        </div>
        <h1 className="text-xl text-center">Our mice weren't able to find any results this time</h1>
        <p className="text-sm text-center">Try different filter options.</p>
      </>
    )
  } else {
    const makeList = (resp: SearchResponse) => (
      <>
      <h1 className="text-3xl mt-8">History of {settingsState?.hotel}</h1>
      {/* <p className="mb-4">{subtitle}</p> */}
      {/* <ResultsList data={resp} settings={settingsState ?? undefined} /> */}
      </>
    );

    for (let i = 0; i < response.series.length; i++) {
      max = Math.max(max, response.series[i].max);
      min = Math.min(min, response.series[i].min);
    }

    content = (
      <div className="h-[960px] ">
        <ResponsiveContainer>
          <ComposedChart
            width={1280}
            height={960}
            data={response.series}
            margin={{
              top: 5,
              right: 30,
              left: 25,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis tickFormatter={moneyFormatter} />
            <Legend onClick={selectLine} />
            {/* TODO: Refactor this to avoid repetition */}
            <Line type="monotone" dataKey="avg" stroke="#8d8d8d" dot={false} strokeWidth={2} hide={labelVisibility.get("avg")} />
            <Line type="monotone" dataKey="min" stroke="#8884d8" dot={false} strokeWidth={2} hide={labelVisibility.get("min")} />
            <Line type="monotone" dataKey="max" stroke="#ffc658" dot={false} strokeWidth={2} hide={labelVisibility.get("max")} />
            <Line type="monotone" dataKey="latest" stroke="#82ca9d" dot={false} strokeWidth={2} hide={labelVisibility.get("latest")} />
            {/* @ts-ignore */}
            <Tooltip content={<CustomTooltip/>} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }

  useEffect(() => {
    fetchHistoryData(settingsState);
  }, []);

  const searchResultDummy = {
    flight: null,
    hotel: {
      totalPrice: {lowerBound: min, upperBound: max},
      isSplitPrice: false,
      resortName: getHotelTitle(settings.hotel),
      resortType: settings.hotel,

      bookUrl: "",
    },
    ticket: null,
    totalPrice: {lowerBound: min, upperBound: max},
    startDate: "12-09-2022",
    durationDays: settings.duration,
  };
  const searchResultDummySettings = {
    flights: true,
    tickets: true,
    hotels: true,
    adults: 2,
    children: 0,
    origin: null,
    dest: "Orlando",
    start: new Date(),
    duration: 14,
    highlight: null,
  };

  const durationOptions = [
    { value: '1', label: '1 night' },
    { value: '7', label: '7 nights' },
    { value: '14', label: '14 nights' }
  ]
  let defDurationIndex = 0;
  switch (settingsState.duration) {
    case 1: defDurationIndex = 0; break;
    case 7: defDurationIndex = 1; break;
    case 14: defDurationIndex = 2; break;
  }

  return (
    <>
      <Navbar isFrontPage={false} />
      <Helmet>
          <title>{"Price history for " + getHotelTitle(settingsState?.hotel) + " - Mousetrack.co.uk"}</title>
      </Helmet>
      <Wrapper className="border-grey-200 border-y-2 py-4">
        <div className="flex gap-4">
          <PeopleCount
            currAdults={settingsState.adults} currChildren={settingsState.children}
            onUpdate={
              (adults: number, children: number) => {
                setSettingsState(
                  prevState => ({ ...prevState, adults: adults, children: children })
                );
              }
            }
          />
          <Select options={durationOptions} isSearchable={false}
            placeholder="Duration" aria-label="Duration of stay at resort"
            defaultValue={durationOptions[defDurationIndex]}
            onChange={
              (newValue: SingleValue<{ value: string; label: string; }>) => {
                setSettingsState(
                  prevState => ({
                    ...prevState,
                    duration: parseInt(
                      newValue?.value ?? durationOptions[defDurationIndex].value
                    )
                  })
                )
              }
            }
            className="min-w-[12rem]" />
        </div>
        <div className="p-2"></div>
        <SearchResultItem data={searchResultDummy} key={0} isFirst={true} isCheapest={false} isLast={true} settings={searchResultDummySettings} isForHistory={true} />
      </Wrapper>
      <Wrapper>
        <section className="pt-4">
          {content}
        </section>
        <Footer />
      </Wrapper>
    </>
  );
}

export default HistoryPage;